import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menuData:[],
}

export const menuSlice = createSlice({
  name: 'menuData',
  initialState,
  reducers: {
    setMenu: (state,action) => {
     
      state.menuData = action.payload
     
    }
  },
})

// Action creators are generated for each case reducer function
export const { setMenu } = menuSlice.actions

export default menuSlice.reducer