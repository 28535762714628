import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sessionData:{TimeLogin:0},
}

export const sessionSlice = createSlice({
  name: 'sessionDataSlice',
  initialState,
  reducers: {
    setsession: (state,action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setsession } = sessionSlice.actions

export default sessionSlice.reducer