"use client";
import Aos from "aos";
import "aos/dist/aos.css";

import "../styles/index.css";
import { useEffect } from "react";
import ScrollToTop from "../components/common/ScrollTop";
import { Provider } from "react-redux";
import { store } from "../store/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from "../config";


//import "bootstrap/dist/js/bootstrap"
const GOOGLE_MAPS = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY||""}&libraries=places&v=weekly&language=en&sensor=true`

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams:null,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  
};

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");

  providerConfig.authorizationParams = {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  }
  
}
function loadScript(src, callback) {
  if(typeof document !== 'undefined'){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
  
    script.onload = () => {
        if (callback) {
            callback();
        }
    };
  
    script.onerror = (err) => {
        console.error(`Failed to load script ${src}:`, err);
    };
  
    document.head.appendChild(script);
  }
  
}
loadScript(GOOGLE_MAPS, () => {});
export default function RootLayout({ children }) {
  useEffect(() => {
    Aos.init({
      duration: 100,
      once: true,
    });
  }, []);
  return (
    <html lang="en">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com"  />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="keywords"
          content="insurance, insurer, policy, coverage, claim, risk, premium, indemnity, loss, insurance broker, insurance agent technology, innovation, digitalization, platform, insurtech, startup, digital insurance, automation, artificial intelligence, blockchain, telematics, data-driven insurance underwriting, London market, Lloyd's of London, underwriter, risk, insurance, reinsurance, specialization, Lloyd's broker" 
        />
        <meta
          name="description"
          content="Insurtech"
        />
        <meta name="ibthemes" content="ATFN" />

        <link rel="icon" href="./favicon.ico" />
        <script src="https://cdn.pagesense.io/js/londonuwcom/349a12132b95498eadfe17acdf697e6b.js" async></script>
        <script type="text/javascript" id="zsiqchat" src="/scripts/saleiq.js" async ></script>

        <script  src="https://www.googletagmanager.com/gtag/js?id=G-EPYTDQTBJG" async></script>
        <script type="text/javascript" id="ganalytics" src="/scripts/ganalitycs.js" async ></script>

        <script type="text/javascript" id="zsiqchat" src="/scripts/googletag.js" async ></script>
        <script type="text/javascript" id="zsiqchat" src="/scripts/marktauto.js" async ></script>
      </head>

      <body>
        <Auth0Provider
          {...providerConfig}
        >
          <Provider store={store}>
            <div className="page-wrapper">
              {children}
              {/* Toastify */}
              <ToastContainer
                position="bottom-right"
                autoClose={300}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={1}
                //pauseOnFocusLoss
                draggable
                //pauseOnHover
                theme="colored"
              />
           
              <ScrollToTop />
            </div>
          </Provider>
        </Auth0Provider>

        <>
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MFWBGG4R"
            height="0" width="0" style={{ display: "none", "visibility": "hidden" }}></iframe>
        </>
      </body>

    </html>
  );
}
