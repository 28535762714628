import { configureStore } from "@reduxjs/toolkit";
import toggleSlice from "../features/toggle/toggleSlice";
import menuSlice from "../features/menu/menuSlice";
import sessionSlice from "../features/session/sessionSlice";
import { createSelector } from 'reselect'
export const store = configureStore({
    reducer: {
        toggle: toggleSlice,
        menuData:menuSlice,
        sessionData:sessionSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});


const selectMenuState = (state) => state.menuData

export const selectMenuData = createSelector(
  [selectMenuState],
  (menuState) => menuState.menuData
)